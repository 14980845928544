import { render, staticRenderFns } from "./myborrow.vue?vue&type=template&id=1ee61dfd&scoped=true&"
import script from "./myborrow.vue?vue&type=script&lang=js&"
export * from "./myborrow.vue?vue&type=script&lang=js&"
import style0 from "./myborrow.vue?vue&type=style&index=0&id=1ee61dfd&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ee61dfd",
  null
  
)

export default component.exports